import React from 'react'
import DetailsSidebar from './ServicesSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const EcommerceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        {/* <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div> */}

                        <div className="services-details-desc pt-100">
                            <span className="sub-title">Goal Setting</span>
                            <h3>The frameworks to define business objectives</h3>
                            
                            <p><strong>SMART Goals:</strong> This framework stands for Specific, Measurable, Achievable, Relevant, and Time-bound. It is a widely used method to define clear and actionable business objectives.</p>

                            <p><strong>OKR (Objectives and Key Results):</strong> This framework defines objectives as high-level, qualitative goals and key results as quantifiable measures of success. It helps to ensure that objectives are aligned with business strategy and that progress is tracked and measured.</p>

                            <p><strong>BSC (Balanced Scorecard):</strong> This framework considers four perspectives for business objectives: financial, customer, internal processes, and learning and growth. It helps to ensure that objectives are balanced and aligned with the overall business strategy.</p>

                            <p><strong>MOSO (Measures of Success and Outcomes):</strong> This framework focuses on defining the outcomes that the business wants to achieve, and the measures of success that will be used to determine if the outcomes have been achieved.</p>

                            <p><strong>McKinsey 7S Framework:</strong> This framework considers seven factors that impact business success: strategy, structure, systems, shared values, skills, style, and staff. It helps to ensure that business objectives are aligned with all aspects of the business.</p>
                           
                            <p>These frameworks can be used to guide digital transformation initiatives and ensure that they are aligned with the overall business strategy. By using a structured approach, organizations can prioritize initiatives, track progress, and measure the impact of their digital transformation efforts.</p>

                            {/* <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Offer </h3>
                                        <ul>
                                            <li>UI/UX Design</li>
                                            <li>Modular Content Management</li>
                                            <li>Front-End Development</li>
                                            <li>Back-end Development</li>
                                            <li>DevOps</li>
                                            <li>Analytics</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                            {/* <h3>E-Commerce Solutions</h3> */}

                            {/* <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Headless eCommerce
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Adobe Magento 
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Shopify eCommerce
                                    </div>
                                </div>
            
                            </div> */}

                            {/* <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <DetailsSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EcommerceDetailsContent